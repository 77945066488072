<template>
  <div>
    <StoreSwitcher v-if="isAdmin" />
    <RentsReportFilter v-model="filter" @filter="loadRents" />
    <RentsReportList
      :rents="rents"
      :payments-value="paymentsValue"
      @change-page="handleChangePage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'
import RentsReportFilter from './RentsReportFilter'
import RentsReportList from '@/components/Reports/Rents/RentsReportList'
import { formatDate } from '@/helpers/date'
import StoreSwitcher from '@/components/Stores/StoreSwitcher'

export default {
  name: 'RentsReport',
  components: {
    RentsReportFilter,
    RentsReportList,
    StoreSwitcher
  },
  mixins: [
    withAsyncAction({
      key: 'rents',
      initialState: [],
      fetcher: {
        methodName: 'fetchRentsReport',
        handler: services.reports.fetchRentsReport
      }
    })
  ],
  data() {
    return {
      filter: {
        fromDate: formatDate(new Date()),
        toDate: formatDate(new Date())
      },
      paymentsValue: []
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId', 'isAdmin'])
  },
  created() {
    this.loadRents()
  },
  methods: {
    handleChangePage(newPage) {
      this.loadRents(newPage, false)
    },
    async loadRents(page = 1, fetchPayments = true) {
      const storeId = this.currentStoreId
      const { fromDate, toDate } = this.filter

      this.fetchRentsReport({ storeId, fromDate, toDate, page, limit: 100 })

      if (fetchPayments) {
        await this.fetchPayments({ storeId, fromDate, toDate })
      }
    },
    async fetchPayments({ storeId, fromDate, toDate }) {
      try {
        const paymentsData = await services.reports.fetchPaymentsReport({
          storeId,
          fromDate,
          toDate
        })
        this.paymentsValue = paymentsData
      } catch (error) {
        this.paymentsValue = []
      }
    }
  }
}
</script>
